<template>
  <div class="job-page">
    <Header />
    <section class="job-page-header container">
      <h2 class="job-page-header-text">Muh Studio</h2>
      <h5>Job Title: Voice Over Talent (Kinyarwanda Speaker)</h5>
      <h6>Location: Montreal, Canada</h6>
      <p>Updated : February 19th, 2021</p>
    </section>
    <section class="job-description container">
      <p>
        <b>Purpose</b>: The role of a <b>Cartoon Voice Over Talent</b> is to
        interpret recording and one or more voice of the cartoon character. This
        is a fulltime multi-episode Cartoon recording. The ideal candidate
        should also help inventing the appropriate voice for some of the
        characters.
      </p>
      <p>
        You will also work with Sound designer, 3D Animators & Director. The
        scripts are in Kinyarwanda and some colleague speaks Kinyarwanda and/or
        French and/or English. The audio will be used online (Youtube, other)
      </p>
      <p>
        You are an intelligent oral (Kinyarwanda/Kirundi) native speaker who
        enjoys reading & recoding voices in a studio. You can interpret both a
        female and a small boy voice in a cartoon children friendly tone. You
        are not frustrated by repeating the scripts and can handle strong
        feedback and criticism.
      </p>
      <p><b>Voice gender</b> Female</p>
      <p><b>Language</b> English (neutral)</p>
      <p><b>Voice age</b> Kid, Young Adult</p>
      <p><b>Voice usage</b> Cartoons</p>
      <p><b>Distribution</b> Online, TV (local), Other</p>

      <h6 class="relevant-voice">
        <b
          >Please provide us with 3 relevant voices recording samples in
          Kinyarwanda to be considered for this position.</b
        >
      </h6>
    </section>
    <section class="responsabilities">
      <div class="container">
        <h6><b>This position is responsible for :</b></h6>
        <ul>
          <li>
            <p>Reading cartoon scripts repeating.</p>
          </li>
          <li><p>Recording voices sounds in a studio environment.</p></li>
          <li><p>Recording lips motion for 3D animation supports.</p></li>
          <li><p>Collaborating with Sound Design and Movie director.</p></li>
          <li>
            <p>
              Responding to feedback and editing/revising content as needed to
              ensure sound satisfaction.
            </p>
          </li>
          <li>
            <p>
              Maintaining proficiency in multiple project-related processes and
              technological.
            </p>
          </li>
          <li>
            <p>
              Maintaining accountability for output quantity, turnaround times
              and quality standardsand being proactive and taking ownership to
              resolve complications and complete project.
            </p>
          </li>
        </ul>
      </div>
    </section>

    <section class="team-spirit">
      <div class="container">
        <h6><b>You will be a strong team player and will possess:</b></h6>
        <ul>
          <li>
            <p>University degree (communications, art would be an asset).</p>
          </li>
          <li><p>Relevant experience.</p></li>
          <li><p>Recent experience with 3-8 years old children.</p></li>
          <li>
            <p>
              Excellent Kinyarwanda Reading and Speaking skills (standard rules
              of grammar).
            </p>
          </li>
          <li><p>Excellent English & French reading.</p></li>
          <li>
            <p>
              Knowledge & experience of Kinyarwanda culture will be considered
              an asset.
            </p>
          </li>
          <li>
            <p>
              The ability to function in a heavily technological, systems-based
              and process-heavy environment.
            </p>
          </li>
          <li>
            <p>
              Strong oral communication skills and the ability to interact with
              demanding colleagues.
            </p>
          </li>
          <li><p>Strong relationship building and collaboration skills.</p></li>
          <li>
            <p>
              The ability to adapt to change and handle multiple and/or
              conflicting projects and priorities.
            </p>
          </li>
          <li>
            <p>
              The ability to work independently and meet deadlines, output goals
              and quality expectations.
            </p>
          </li>
        </ul>
      </div>
    </section>
    <section class="job-salary">
      <div class="container">
        <p><b>Salary:</b> $35,000 CAD + vacation pay.</p>

        <p><b>Term:</b> Full-time/ 1 year.</p>
        <p>Contact: Recruiter for further information.</p>
      </div>
    </section>
    <Footer />
  </div>
</template>

<script>
import Header from "../layout/Header.vue";
import Footer from "../layout/Footer.vue";
export default {
  name: "Job",
  components: { Header, Footer },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.job-page-header-text {
  color: #3a50b3;
}
.responsabilities,
.team-spirit {
  background-color: white;
}

.job-salary,
.team-spirit,
.responsabilities,
.job-description,
.relevant-voice,
.job-page-header-text {
  padding-top: 30px;
  padding-bottom: 30px;
}

ul li {
  list-style: circle;
  margin-left: 20px;
}
</style>
