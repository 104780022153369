<template>
  <div class="header">
    <nav>
      <div class="nav-wrapper">
        <a v-on:click="goHome" class="brand-logo center">
          <img
            src="../assets/img/logo-muhaye-white.png"
            alt=""
            class="brand-logo"
          />
        </a>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "Header",
  methods: {
    goHome() {
      this.$router.push({ name: "Home" });
    },
  },
};
</script>

<style scoped>
.nav-wrapper {
  background-color: #3a50b3;
}
.brand-logo {
  position: absolute;
  width: 70px;
  top: 5px;
  object-fit: cover;
}
</style>
