<template>
  <div id="contact-form" class="contact-form container">
    <h1 class="contact-form_title">Contactez-nous</h1>
    <div class="separator"></div>

    <div v-if="isSending" class="loading">Envoyer...</div>

    <form class="form" @submit="onSubmit">
      <input
        required
        name="name"
        v-model="contact.name"
        placeholder="nom"
        type="text"
        autocomplete="off"
      />
      <input
        required
        name="email"
        v-model="contact.email"
        placeholder="e-mail"
        type="email"
        autocomplete="off"
      />
      <textarea
        name="message"
        v-model="contact.message"
        rows="4"
        placeholder="message"
      ></textarea>
      <button class="button blue">envoyer</button>
    </form>
  </div>
</template>

<script>
export default {
  el: "#contact-form",

  data() {
    return {
      contact: {
        name: "",
        email: "",
        message: "",
      },

      isSending: false,
    };
  },

  methods: {
    /**
     * Clear the form
     */

    clearForm() {
      for (let field in this.contact) {
        this.contact[field] = "";
      }
    },

    /**
     * Handler for submit
     */
    onSubmit(evt) {
      evt.preventDefault();

      this.isSending = true;

      setTimeout(() => {
        // Build for data
        let form = new FormData();
        var formJSON = {};

        for (let field in this.contact) {
          form.append(field, this.contact[field]);
          console.log(field + ":" + this.contact[field]);
          formJSON[field] = this.contact[field];
        }

        console.log("stuff:" + formJSON);

        // Send form to server
        // https://80i0bur7i5.execute-api.ca-central-1.amazonaws.com/test
        this.$http
          .post("https://form.muh.mobi/test/helloworld", formJSON)
          .then((response) => {
            console.log(response);
            this.clearForm();
            this.isSending = false;
          })
          .catch((e) => {
            console.log(e);
          });
      }, 1000);
    },
  },
};
</script>

<style>
body {
  background: #f1f1f1;
  font-family: "Roboto", sans-serif;
}

.contact-form {
  font-family: 16px;
  margin: 0 auto;
  max-width: 600px;
}

h1 {
  font-family: "Orbitron", sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  text-transform: lowercase;
  color: #168cf4;
}

.contact-form .separator {
  border-bottom: solid 1px #ccc;
  margin-bottom: 15px;
}

.contact-form .form {
  display: flex;
  flex-direction: column;
  font-size: 16px;
}

.contact-form_title {
  color: #333;
  text-align: left;
  font-size: 28px;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form textarea {
  border: solid 1px #e8e8e8;
  font-family: "Roboto", sans-serif;
  padding: 10px 7px;
  margin-bottom: 15px;
  outline: none;
}

.contact-form textarea {
  resize: none;
}

.contact-form .button {
  background: #da552f;
  border: solid 1px #da552f;
  color: white;
  cursor: pointer;
  padding: 10px 50px;
  text-align: center;
  text-transform: uppercase;
}

.contact-form .button:hover {
  background: #ea532a;
  border: solid 1px #ea532a;
}

.contact-form input[type="email"],
.contact-form input[type="text"],
.contact-form .button {
  font-size: 15px;
  border-radius: 3px;
}

.contact-form textarea {
  height: 12rem;
}
</style>
