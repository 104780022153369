<template>
  <div class="hello">
    <header>
      <div class="row">
        <div class="col s6 left-banner animated fadeInLeft">
          <div class="container center">
            <h1
              class="left-banner-title-text banner-title animated fadeInLeft center"
            >
              muh mobile
            </h1>
            <p class="banner-text animated fadeInLeft slower">
              Dévellopement d’applications web et mobiles
            </p>
            <button class="btn left-banner-button animated fadeInLeft slower">
              Contact
            </button>
          </div>
        </div>
        <div class="col s6 right-banner animated fadeInRight">
          <div class="container center">
            <h1
              class="right-banner-title-text banner-title animated fadeInRight"
            >
              muh studio
            </h1>
            <p class="banner-text animated fadeInRight slower">
              Productions et animations 3D
            </p>
            <button
              v-on:click="hirePage"
              class="btn right-banner-button animated fadeInRight slower"
            >
              Postulez!
            </button>
          </div>
        </div>
      </div>
      <img
        src="../assets/img/logo-muhaye-white.png"
        alt=""
        class="brand-logo"
      />
    </header>
    <!--   Icon Section   -->
    <section class="icon-section">
      <div class="container">
        <p class="title center animated fadeInUp slower">
          Muh-Mobile, Service de Développement
        </p>
        <div class="row">
          <div class="col s12 m6">
            <div class="icon-block">
              <h2 class="center">
                <img
                  class="service-icon"
                  src="../assets/img/android-icon.png"
                  alt="app-mobile"
                />
              </h2>
              <h5 class="center">Application mobile</h5>

              <p class="small-text">création, maintenance et soutien</p>
            </div>
          </div>

          <div class="col s12 m6">
            <div class="icon-block">
              <h2 class="center light-blue-text">
                <img
                  class="service-icon"
                  src="../assets/img/web-icon.png"
                  alt="service-web"
                />
              </h2>
              <h5 class="center">services web</h5>

              <p class="small-text">
                création complète d’application et de site web.
              </p>
            </div>
          </div>

          <div class="col s12 m6">
            <div class="icon-block">
              <h2 class="center light-blue-text">
                <img
                  class="service-icon"
                  src="../assets/img/multiple-icon.png"
                  alt="consultation"
                />
              </h2>
              <h5 class="center">gestion de base de données</h5>

              <p class="small-text">
                Stockage en ligne de donnée sensible sécurisé
              </p>
            </div>
          </div>
          <div class="col s12 m6">
            <div class="icon-block">
              <h2 class="center light-blue-text">
                <img
                  class="service-icon"
                  src="../assets/img/db-icon.png"
                  alt="database"
                />
              </h2>
              <h5 class="center">consultations</h5>

              <p class="small-text">analyse, conception, supervision</p>
            </div>
          </div>
        </div>
        <!-- Youtube Section -->
        <section class="video-section">
          <h2 class="title center">Suivez-nous sur YouTube</h2>
          <div class="center video-container">
            <youtube
              :video-id="videoId"
              ref="youtube"
              @playing="playing"
            ></youtube>
          </div>
        </section>
      </div>
    </section>

    <!-- Contact Form -->
    <contact />

    <Footer />
  </div>
</template>

<script data-ad-client="ca-pub-9321576276642320" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
<script>
import Contact from "../../src/components/Contact.vue";
import Vue from "vue";
import VueYoutube from "vue-youtube";
import Footer from "../layout/Footer.vue";

Vue.use(VueYoutube);

export default {
  name: "Home",

  data() {
    return {
      videoId: "pE-kRpxN2kk",
    };
  },
  props: {
    msg: String,
  },
  components: {
    Contact,
    Footer,
  },
  computed: {
    player() {
      return this.$refs.youtube.player;
    },
  },
  methods: {
    playVideo() {
      this.player.playVideo();
    },
    playing() {
      console.log(" we are watching!!!");
    },
    hirePage() {
      this.$router.push({ path: "jobs" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
/* HEADER */
.left-banner {
  background-color: #3a50b3;
  height: 80vh;
}

.right-banner {
  background-color: #e9b947;
  height: 80vh;
}

.banner-title {
  font-family: Orbitron;
  font-style: normal;
  font-weight: bold;
  font-size: 46px;
  line-height: 40px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 150px;
}

.left-banner-title-text {
  color: #e9b947;
}
.right-banner-title-text {
  color: #3a50b3;
}

.right-banner-button {
  background-color: #3a50b3;
}

.left-banner-button {
  background-color: #e9b947;
}

.banner-text {
  margin-top: 20px;
  font-family: Orbitron;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 3px;
  display: flex;
  align-items: center;
  text-align: center;
  color: white;
  height: 10vh;
}
.brand-logo {
  position: absolute;
  width: 80px;
  left: 15px;
  top: 17px;
  object-fit: cover;
}
.title-text {
  font-family: "Orbitron", sans-serif;
  letter-spacing: 3px;
}

.icon-section,
.video-section {
  padding-bottom: 45px;
}
.video-container {
  margin-top: 60px;
}
.quote-button {
  width: 215px;
  height: 56px;
  border: 1px solid white;
  color: white;
  border-radius: 50px;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 29px;
  background-color: Transparent;
  margin-top: -10px;
}

.service-icon {
  width: 70px;
  height: 70px;
}
.small-text {
  color: #727272;
  text-align: center;
}
.title {
  font-family: "Orbitron", sans-serif;
  font-weight: 600;
  font-size: 36px;
  line-height: 43px;
  text-transform: lowercase;
  color: #168cf4;
}
.title-text {
  font-family: "Orbitron", sans-serif;
  letter-spacing: 3px;
}
</style>
