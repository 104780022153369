<template>
  <div class="footer">
    <footer class="page-footer">
      <div class="container">
        <div class="row">
          <div class="col m6 s12">
            <h5 class="white-text link">MUH MOBILE, société par actions</h5>
          </div>
          <div class="col m4 offset-l2 s12 link">
            <h5 class="white-text">Liens</h5>
            <ul>
              <li>
                <a class="white-text" href="https://console.tpstvq.muh.mobi"
                  >Rapport de taxes automatique</a
                >
              </li>
              <li>
                <a class="white-text" href="https://tpstvq.muh.mobi"
                  >Calculatrice de taxes</a
                >
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="footer-copyright">
        <div class="container white-text">
          © 2021 Tous droits réservés
          <a class="white-text right" href="https://www.muh.mobi/">Muh.mobi</a>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
.page-footer {
  background-color: #3a50b3;
}
.footer {
  padding-top: 100px;
}
.footer-copyright,
.link {
  text-transform: lowercase;
}
</style>
